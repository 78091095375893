import {Toast} from "vant";

export function loading(msg){
    Toast.loading({message:msg || "加载中...",duration:0,position:"top"})
}
export function success(msg){
    Toast.loading({message:msg || "操作成功...",position:"top"})
}
export function fail(msg){
    Toast.fail({message:msg || "操作成功...",position:"top"})
}
export function clear(){
    Toast.clear();
}

