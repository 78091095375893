<template>
  <div class="device_list" style=" overflow-y:hidden">
    <van-sticky>
      <div class="device_fixed">
        <div class="device_tab">
        <span
            @click="changeTabs(x,key)"
            v-for="(x,key) in tabsList"
            :key=key
            :class="keyIndex == x.value ? 'device_checked':''"
        >{{ x.name }}({{ x.count }})</span>
        </div>
        <div class="search_box" style="background-color:#FFFFFF">
          <van-field
              v-model="keyword"
              clearable
              :placeholder="$t('设备名称或IMEI')"
              use-button-slot
          >
            <div slot="button">
              <van-button  size="small"   type="info" @click="search" icon="search">
                {{$t("搜索")}}
              </van-button>

              <van-button style="margin-left: 10px"  size="small"   type="info" @click="goDeviceAdd()"  plain  icon="add-o">

              </van-button>
            </div>

          </van-field>
        </div>
        <div style="background-color:#FFFFFF">
          <van-field
              :value="this.cascaderFieldValue"
              readonly
              clickable
              :label="$t('分组筛选')"
              :placeholder="$t('请选择设备分组')"
              @click-input="cascaderShow"
          >
            <template #button>
              <van-button @click="mapOverview"  size="small" plain  type="info" >
                {{$t("位置全览")}}
              </van-button>
            </template>>
          </van-field>
          <div style="color: #C8C9CC;font-size: 12px;width: 100%;text-align: center;">
            {{$t('左滑设备信息操作设备')}}
          </div>
        </div>

      </div>
    </van-sticky>
    <div>
      <div v-if="list.length>0">
        <van-list
            v-model="loadMore"
            :finished="loadAll"
            :finished-text="$t('没有更多了')"
            @load="onLoadMore"
        >
        <van-swipe-cell :right-width="150"  v-for="(x,key) in list" :key=key>
            <div class="device_item" @click="locationEvent(x.imei)">
                <div class="device_avatar">
                  <van-image
                      width="50"
                      height="50"
                      :src="x.babyAvatar?x.babyAvatar:require('/static/resources/device/person.png')"
                  />
                </div>
              <div class="device_info">
                <div class="device_name">
                  <div style="width: 75%">
                    <span  :style="{color:x.online?'#000000':'#C8C9CC'}">{{x.babyName}}</span>
                        &nbsp;
                        <van-tag :color="x.online?'':'#C8C9CC'" :type="x.location.stopDurationDesc?'danger':'success'">
                          {{x.location.stopDurationDesc?((x.online?$t("在线"):$t("离线")) + $t("停留") + x.location.stopDurationDesc):(x.online?$t("在线运动中"):$t("离线")) }}
                        </van-tag>
                  </div>
                  <div style="padding-right: 5px">
                    <Electricity :electricity="x.electricity"/>
                  </div>
                </div>
                <div style="color:  #878B8E">
                      IMEI:{{x.imei}}
                </div>
              </div>
            </div>
          <template #right>
            <div class="ac" style="height: 100%">
              <van-button @click="unbindEvent(x.imei)" type="danger" >{{$t('解绑')}}</van-button>

              <van-button @click="editEvent(x.imei)" type="default" >{{$t('编辑')}}</van-button>
<!--              <van-button @click="goActionItem(x)" type="primary" >更多</van-button>-->
            </div>
          </template>
          <van-divider style="margin: 0"/>
        </van-swipe-cell>

        </van-list>

        <van-popup v-model="showPicker" round position="bottom">
<!--          <van-picker-->
<!--              show-toolbar-->
<!--              :columns="groupTree"-->
<!--              @cancel="showPicker = false"-->
<!--              title="请选择分组"-->
<!--          />-->
          <div>
            <van-row class="cascader_toolbar">
              <van-col @click="showPicker = false" class="cascader_toolbar_cancel" span="8">{{$t("取消")}}</van-col>
              <van-col class="cascader_toolbar_title" span="8">{{$t("请选择分组")}}</van-col>
              <van-col @click="cascaderConfirm" class="cascader_toolbar_confirm" span="8">{{$t("确认")}}</van-col>
            </van-row>
          </div>
        <van-cascader @change="cascaderChange" @finish="cascaderFinish"
            :title="$t('请选择设备分组')"
            :options="groupTree"
            :field-names="fieldNames"
            :show-header="false"
        />

        </van-popup>

      </div>

      <div v-else style="color:#878B8E;font-size:15px;margin-top: 145px;height:500px;text-align: center;">{{loading?"":$t('暂无数据')}}</div>

    </div>

  </div>
</template>

<script>
import Electricity from '../../../components/electricity'
import {loading,clear,success,fail} from "../../../util/toast-util";
import {deviceList, getBaseInfo, unBind} from "../../../api/device";
import {Button, Field, SwipeCell,Tag,Image,List,Picker,Popup,Cascader,Row,Col,Divider,Sticky,Dialog} from "vant";
import {readCommonParam} from "../../../util/session-storage-utils";
import {deviceGroupTree} from "../../../api/devicegroup";
import {
  changeDevice,
  navigateToDeviceAdd,
  navigateToDeviceEdit, navigateToDeviceMapOverview,
} from "../../../util/jsinterfaces-util";
export default {
  name:"DeviceList",
  components: {
    Electricity,
    [SwipeCell.name]:SwipeCell,
    [Field.name]:Field,
    [Button.name]:Button,
    [Tag.name]:Tag,
    [Image.name]:Image,
    [List.name]:List,
    [Picker.name]:Picker,
    [Popup.name]:Popup,
    [Cascader.name]:Cascader,
    [Row.name]:Row,
    [Col.name]:Col,
    [Divider.name]:Divider,
    [Sticky.name]:Sticky,
    [Dialog.name]:Dialog,
  },
  data () {
    return {
      colSpan:6,
      loading: true,
      loadMore:false,
      loadAll:false,
      current: 3,
      keyIndex: '0',
      keyword:"",
      page: 1,
      size: 30,
      list: [],
      listInfo: {
        allTotal: 0,
        onlineTotal: 0
      },
      finished:false,
      groupTree:[],
      showPicker:false,
      fieldNames: {
        text: 'title',
        value: 'value',
        children: 'children',
      },
      cascaderFieldValue:null,
      groupId:null
    }
  },
  computed: {
    tabsList () {
      const { allTotal, onlineTotal } = this.listInfo
      return [
        { name:this.$t('全部'),value:'0', count: allTotal },
        { name:this.$t('在线'),value:'1', count: onlineTotal },
        { name:this.$t('离线'), value:'2',count: allTotal - onlineTotal }
      ]
    }
  },
  created(){
    this.init();
    document.title = this.$t("设备列表");
    readCommonParam(this)
  },
  methods: {
    init(){
      this.list = []
      this.page = 1
      this.loadAll = false
      this.refDeviceList()
      this.groupListTree();

    },
    cascaderConfirm(){

      if(!this.cascaderChangeValue){
        fail("请选择");
        return;
      }
      this.cascaderFinish(this.cascaderChangeValue)
    },
    cascaderChange(value){

      this.cascaderChangeValue = value;
      window.console.log(value)
    },
    cascaderFinish({value,selectedOptions}){
      this.showPicker = false
      this.groupId = value;
      window.console.log(value,this.groupId)
      this.cascaderFieldValue = selectedOptions.map((option) => option.title).join('/');
      this.search();
    },
    cascaderShow(){
      this.showPicker = true
      this.cascaderChangeValue = null;
    },
    onLoadMore(){
      this.page = this.page + 1
      this.refDeviceList();
    },
    groupListTree(){
      deviceGroupTree().then((resp) => {
        // let groupTree = [];
        //
        // this.groupTreeHandle(groupTree,resp.data);
        // this.groupTree = groupTree;
        // window.console.log(groupTree)
        let data = resp.data;
        this.groupChildrenHandle(data);
        this.groupTree = data;
      })
    },
    groupChildrenHandle(list){
      for (let i = 0; i < list.length; i++) {
        let dg = list[i];
        if(dg.children && dg.children.length > 0){
          this.groupChildrenHandle(dg.children)
        }else{
          dg.children = null;
        }
      }
    },
    groupTreeHandle(groupTree,list){
      for (let i = 0; i < list.length; i++) {
        let dg = list[i];
        let item = {text:dg.title};
        groupTree.push(item)
        if(dg.children){
          item.children = [];
          this.groupTreeHandle(item.children,dg.children)
        }
      }
    },
    refDeviceList(){
      this.getDeviceList({
            type: this.keyIndex,
            size: this.size,
            number: this.page,
            keyword: this.keyword,
            noCount:this.loadMore,
            useWgs84:true,
            mapGeocoder:false,
            motionCheck:false,
            groupId:this.groupId
          }
      );
    },
    goHome(device){
      window.console.log(device);
    },
    getDeviceList(obj){

      if(!this.loadMore){
        this.loading = true
        loading()
      }
      deviceList(obj).then((rsp) => {
        this.loading = false
        if(!this.loadMore){
          clear()
        }
        let data = rsp.data;
        if(!this.loadMore){
          //加载分页数据没有统计数据不能更新
          this.listInfo = { allTotal: data.allTotal, onlineTotal: data.onlineTotal }
        }

        let dataLength = data.dataList.length
        if(dataLength == 0 ){
          this.loadAll = true;
        }
        if (!dataLength) {
          // this.list = [];
          this.loadMore = false;
          return
        }
        this.list = this.list.concat(data.dataList)
        this.loadMore = false;
      })

    },
    changeTabs(x,key){
      window.console.log(x,key)
      this.keyIndex =x.value
      this.page = 1
      this.loadAll = false
      this.list = []
      this.refDeviceList()
    },
    search(){
      this.page = 1
      this.loadAll = false
      this.list = []
      this.refDeviceList()
    },
    goDeviceAdd(){
      navigateToDeviceAdd()
    },
    mapOverview(){
      navigateToDeviceMapOverview();
    },
    goTrack(item) {
      this.unbindEvent(item.imei)
    },
    goSetting(item){
      window.console.log(item)
    },
    goActionItem(record){
      window.console.log(record)
    },
    goPay(){

    },
    locationEvent(imei){
      getBaseInfo(imei).then(res => {
        let device = res.data;
        changeDevice(imei,device.name,device.avatar);
      })

    },
    editEvent(imei){
      navigateToDeviceEdit(imei)
    },
    unbindEvent(imei){
      Dialog.confirm({
        title: this.$t("操作确认"),
        message: this.$t('确认解绑此设备'),
      })
      .then(() => {
        this.unbind(imei);
      })
      .catch(() => {
        // on cancel
      });
      // wx.showModal({
      //   title: '',
      //   content: '确认解绑此设备？',
      //   success: (res) => {
      //     if (res.confirm) {
      //       this.unbind(imei);
      //     } else if (res.cancel) {
      //     }
      //   }
      // })
    },
    unbind(imei){
      unBind(imei).then(() => {
          success(this.$t('设备解绑成功'))
          this.init()
      })

    },
  },

};
</script>

<style lang="less" scoped>
.device_list{
  height: 100%;
  background: #fff;
  .device_fixed{
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .device_tab{
    background: #fff;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 14px;
    color: #000;
    >span{
      display: inline-block;
      width: 33.3%;
      border-bottom: 1px solid #fff;
      &:nth-child(3){
        //&::before{
        //  display: inline-block;
        //  content: '';
        //  height: 16px;
        //  width: 16px;
        //  border-radius: 50%;
        //  background: #878B8E;
        //}
      }
      &:nth-child(2){
        //&::before{
        //  display: inline-block;
        //  content: '';
        //  height: 16px;
        //  width: 16px;
        //  border-radius: 50%;
        //  background: rgb(62, 236, 62);
        //}
      }
    }
    .device_checked{
      color: #4388FF;
      border-bottom: 1px solid #4388FF;
    }
  }
  .device_item{
    width: 100%;
    display: flex;
    align-items:center;
    padding: 10px;
    font-size: 13px;
    .device_info{
      padding-left: 20px;
      width: 100%;
      .device_name{
        display: flex;
      }
    }
  }


  .search_img,.search_del{
    height: 18px;
    width: 18px;
    margin-top: 11px;
  }
  /deep/.device_batteray{
    margin-right: 10px;
  }
  .device_guiji,.device_set,.device_dingwei{
    height: 15px;
    width: 15px;
    // vertical-align: bottom;
    margin-top:-2px;
  }
  .device_guiji{
    height: 14px;
    width: 14px;
    margin-top:-3px;
  }

  .device_more{
    height: 15px;
    width: 15px;
  }
  .device_person{
    height: 38px;
    width: 38px;
  }
}
.border-left{
  border-left:1px solid #C8C9CC
}
.addButton{
  position: fixed;
  right: 20px;
  bottom:30px;
  z-index: 3;
}
.button-text{
  line-height: 13px
}
.ac{
  width: 100%;height: 100%;display: flex;align-items:center; justify-content:center;
}
.ac button{
  margin: 0 5px;
}
.cascader_toolbar{
  .cascader_toolbar_cancel{
    color: #969799;
    height: 100%;
    padding: 5px 16px;
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .cascader_toolbar_title{
    max-width: 50%;
    padding: 5px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .cascader_toolbar_confirm{
    color: #576b95;
    height: 100%;
    padding: 5px 16px;
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: right;
  }
}
</style>
