<template>
<div>
  {{$t("电量")}}:{{electricity }}%
</div>
</template>

<script>
export default {
  name: "index",
  props:{
    electricity:Number
  }
}
</script>

<style scoped>

</style>
